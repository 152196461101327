import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I’m writing to you this week from the `}<a parentName="p" {...{
        "href": "http://www.iirusa.com/prepaid/welcome-page.html"
      }}>{`Prepaid Expo in Orlando`}</a>{`. If the person in charge of the air conditioning in this place happens to be reading this, would you mind turning it down? I came to Orlando to escape the frigid temperature. Thanks.`}</p>
    <p>{`Anyhoo. I just finished my first TV spot as PayPerks CEO on a so-called "widely syndicated" show called ‘Prepaid TV’. (link to come post conference)`}</p>
    <p>{`I had to edit myself to 30 second sound bites but one question I felt deserved a longer answer was:`}<em parentName="p">{`What opportunities for innovation do you see in prepaid’s near future?`}</em></p>
    <p>{`To summarize my answer: The biggest opportunity for innovation in prepaid is not in product – it’s in how we communicate the value of the product. This will unlock exponential value as smarter decisions in prepaid increase value for both the user and the players in the prepaid chain.`}</p>
    <p>{`It occurred to me how mundane my answer would have been at almost any other conference. But for some reason, there hasn’t been much application of game dynamics and behavior economics in the prepaid space. Well, until PayPerks of course :-).`}</p>
    <p>{`(For those who don’t know, PayPerks is introducing game dynamics to prepaid: awarding points for behaviors like taking an online curriculum; making signature based transactions at POS; and adding Direct Deposit loads to your card.)`}</p>
    <p>{`More to come tomorrow.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      